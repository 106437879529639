import { useCallback, useEffect, useState } from 'react';

export default function usePageVisibility() {
  const [isPageVisible, setIsPageVisible] = useState(document.visibilityState === 'visible');

  const onVisibilityChange = useCallback(() => {
    setIsPageVisible(document.visibilityState === 'visible');
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [onVisibilityChange]);

  return {
    isPageVisible,
  };
}
