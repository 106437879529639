import { useCallback, useEffect, useMemo, useState } from 'react';
import { providers } from 'ethers';

import {
  getLocalStorageIsMetamaskWalletConnected,
  setLocalStorageIsMetamaskWalletConnected,
} from '../utilities/localStorage';
import { logEthereumError } from '../utilities/errorLogger';
import { WalletCommonType } from '../models/walletCommonType';
import { hasMultipleWalletPermissions } from '../utilities/ethereum';
import { Web3WalletPermission } from '../models/web3WalletPermission';

export type UseMetamaskType = WalletCommonType & {
  isMetamaskInstalled: boolean;
  handleChainChangedEvent: (newChainId: string) => void;
};

export default function useMetamask(): UseMetamaskType {
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [chainId, setChainId] = useState<number>();

  const provider = useMemo(() => {
    if (typeof window === 'undefined') {
      return;
    }

    return window.ethereum;
  }, []);

  const isMetamaskInstalled = useMemo(() => {
    return typeof provider !== 'undefined' && provider.isMetaMask && !provider.isBraveWallet;
  }, [provider]);

  const web3Provider = useMemo(() => {
    if (!provider) {
      return undefined;
    }

    return new providers.Web3Provider(provider);
  }, [provider]);

  const walletName = useMemo(() => {
    if (!provider) {
      return undefined;
    }

    return 'MetaMask';
  }, [provider]);

  const updateIsWalletConnected = useCallback((value: boolean) => {
    setIsWalletConnected(value);
    setLocalStorageIsMetamaskWalletConnected(String(value));
  }, []);

  const connect = useCallback(async () => {
    if (!web3Provider) {
      return {
        connected: false,
      };
    }

    let accounts: string[];
    let permissions: Web3WalletPermission[];
    try {
      [accounts, permissions] = await Promise.all([
        web3Provider.listAccounts(),
        provider.request({
          method: 'wallet_getPermissions',
          params: [
            {
              eth_accounts: {},
            },
          ],
        }),
      ]);
    } catch (error) {
      logEthereumError(error);
      return {
        connected: false,
      };
    }

    if (!accounts.length || hasMultipleWalletPermissions(permissions)) {
      try {
        await provider.request({
          method: 'wallet_requestPermissions',
          params: [
            {
              eth_accounts: {},
            },
          ],
        });
      } catch (error) {
        logEthereumError(error);
        return {
          connected: false,
        };
      }
    }

    updateIsWalletConnected(true);

    return {
      connected: true,
    };
  }, [web3Provider, provider, updateIsWalletConnected]);

  const disconnect = useCallback(() => {
    updateIsWalletConnected(false);
  }, [updateIsWalletConnected]);

  const isAccountConnected = useCallback(async () => {
    if (!web3Provider) {
      return false;
    }

    const accounts = await web3Provider.listAccounts();

    return accounts.length > 0;
  }, [web3Provider]);

  const initConnection = useCallback(async () => {
    if (!provider) {
      return false;
    }

    const localStorageIsConnected = getLocalStorageIsMetamaskWalletConnected() === 'true';
    if (!localStorageIsConnected) {
      return false;
    }

    const isAccountStillConnected = await isAccountConnected();
    if (!isAccountStillConnected) {
      updateIsWalletConnected(false);
      return false;
    }

    updateIsWalletConnected(true);

    return true;
  }, [isAccountConnected, provider, updateIsWalletConnected]);

  useEffect(() => {
    if (!provider || !isWalletConnected) {
      return;
    }

    provider.request({ method: 'eth_chainId' }).then((result: string) => {
      setChainId(parseInt(result, 16));
    });
  }, [isWalletConnected, provider]);

  const handleChainChangedEvent = useCallback((newChainId: string) => {
    setChainId(parseInt(newChainId, 16));
  }, []);

  const handleErrorEvent = useCallback((error: any) => {
    logEthereumError(error);
  }, []);

  return {
    provider,
    web3Provider,
    isWalletConnected,
    isMetamaskInstalled,
    connect,
    disconnect,
    handleDisconnectEvent: disconnect,
    initConnection,
    chainId,
    handleChainChangedEvent,
    handleErrorEvent,
    walletName,
  };
}
