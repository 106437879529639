import { ReactNode, useCallback } from 'react';
import cx from 'classnames';

import { useRouter } from 'next/router';
import styles from './ShinyButton.module.css';
import { ShinyButtonColorEnum } from '../../shared/consts/shinyButtonColors';
import Sparkle from '../../assets/sparkle.svg';

type ButtonColors = keyof typeof ShinyButtonColorEnum;

type ShinyButtonProps = {
  onClick?: () => void;
  children: ReactNode;
  dense?: boolean;
  color?: ButtonColors;
  grayText?: boolean;
  small?: boolean;
  medium?: boolean;
  className?: string;
  disabled?: boolean;
  toPage?: string;
  shineAnimation?: boolean;
};

const ButtonWrapper = ({
  children,
  shineAnimation,
  color,
}: {
  children: ReactNode;
  shineAnimation?: boolean;
  color?: ButtonColors;
}) => {
  if (shineAnimation) {
    return (
      <div className={cx(styles.btnWrap, color === ShinyButtonColorEnum.green && styles.greenShine)}>
        <Sparkle className={cx(styles.sparkle, styles.spOne)} />
        <Sparkle className={cx(styles.sparkle, styles.spTwo)} />
        {children}
      </div>
    );
  }
  return <>{children}</>;
};

const ShinyButton = ({
  onClick,
  dense,
  children,
  small,
  medium,
  color,
  grayText,
  className,
  disabled,
  toPage,
  shineAnimation,
}: ShinyButtonProps) => {
  const { push: routerPush } = useRouter();

  const handleToPage = useCallback(() => {
    if (!toPage) {
      return;
    }
    if (toPage.startsWith('http') || toPage.startsWith('www.')) {
      const newWindow = window.open(toPage, '_blank', 'noopener,noreferrer');
      if (newWindow) {
        newWindow.opener = null;
      }
      return;
    }
    routerPush(toPage);
  }, [routerPush, toPage]);

  return (
    <ButtonWrapper shineAnimation={shineAnimation} color={color}>
      <button
        disabled={disabled}
        type="button"
        className={cx(
          styles.root,
          dense && styles.dense,
          color === ShinyButtonColorEnum.silver && styles.silver,
          color === ShinyButtonColorEnum.green && styles.green,
          color === ShinyButtonColorEnum.blue && styles.blue,
          color === ShinyButtonColorEnum.turquoise && styles.turquoise,
          grayText && styles.grayText,
          small && styles.small,
          medium && styles.medium,
          shineAnimation && styles.shineAnimation,
          className,
        )}
        onClick={onClick ?? handleToPage}
        aria-hidden="true"
      >
        <span>{children}</span>
      </button>
    </ButtonWrapper>
  );
};

export default ShinyButton;
