import React, { createContext, ReactElement, ReactNode, useContext, useMemo, useState } from 'react';
import config from '../../config';

type AppContextType = {
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isWalletInfoModalOpen: boolean;
  setIsWalletInfoModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  displayUserAuth: boolean;
  setDisplayUserAuth: React.Dispatch<React.SetStateAction<boolean>>;
  displayContest: boolean;
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

export function AppProvider({ children }: { children: ReactNode }): ReactElement {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isWalletInfoModalOpen, setIsWalletInfoModalOpen] = useState(false);
  const [displayUserAuth, setDisplayUserAuth] = useState(false);

  const displayContest = useMemo(() => {
    return config.contest.active === 'true' && new Date(config.contest.startDate).getTime() <= new Date().getTime();
  }, []);

  const value: AppContextType = useMemo(
    () => ({
      isDrawerOpen,
      setIsDrawerOpen,
      isWalletInfoModalOpen,
      setIsWalletInfoModalOpen,
      displayUserAuth,
      setDisplayUserAuth,
      displayContest,
    }),
    [isDrawerOpen, isWalletInfoModalOpen, displayUserAuth, displayContest],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export const useAppContext = (): AppContextType => {
  return useContext(AppContext);
};
