import React, { ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { ethers, providers, utils } from 'ethers';

import useWallet from '../hooks/useWallet';
import WalletOptionsEnum from '../consts/walletOptionsEnum';
import useAuth from '../hooks/useAuth';
import { useAccessTokenContext } from './AccessToken.context';
import useWalletEvents from '../hooks/useWalletEvents';
import { logEthereumError } from '../utilities/errorLogger';
import EthereumErrorCodes from '../consts/ethereumErrors';
import { ConnectWalletType } from '../models/connectWalletType';

type UserContextType = {
  disconnect: () => void;
  connect: (wallet: ConnectWalletType) => Promise<boolean>;
  web3Provider?: providers.Web3Provider;
  signer?: providers.JsonRpcSigner;
  currentAddress: string;
  connectedWith: WalletOptionsEnum;
  isMetamaskInstalled: boolean;
  isWalletConnected: boolean;
  isUserConnected: boolean;
  initGuestToken: () => Promise<void>;
  signChallenge: (referrerToken?: string) => Promise<void>;
  isAcceptedChainId: boolean;
  walletName?: string;
  reinitAccessToken: () => Promise<void>;
  disconnectWallet: () => void;
};

export const UserContext = React.createContext<UserContextType>({} as UserContextType);

export const UserProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const {
    signer,
    web3Provider,
    connectedWith,
    isWalletConnected,
    currentAddress,
    connect,
    isMetamaskInstalled,
    disconnect: disconnectWallet,
    provider,
    setCurrentAddress,
    handleDisconnectEvent: walletHandleDisconnectEvent,
    initConnection: initWalletConnection,
    isAcceptedChainId,
    handleChainChangedEvent,
    handleErrorEvent,
    walletName,
  } = useWallet();
  const { getChallenge, verifyChallenge, getGuestToken } = useAuth();
  const {
    accessToken,
    updateAccessToken,
    isAccessTokenWithWallet,
    removeAccessToken,
    initAccessToken,
    accessTokenWalletAddress,
  } = useAccessTokenContext();

  const isUserConnected = useMemo(
    () => isWalletConnected && isAccessTokenWithWallet,
    [isAccessTokenWithWallet, isWalletConnected],
  );

  const signChallenge = useCallback(
    async (referrerToken?: string) => {
      const challenge = await getChallenge((await signer?.getAddress()) as string, referrerToken);

      let signature;
      try {
        signature = await signer?.signMessage(ethers.utils.arrayify(challenge));
      } catch (error) {
        logEthereumError(error);
        throw error;
      }

      const result = await verifyChallenge(challenge, signature as string);

      updateAccessToken(result);
    },
    [getChallenge, signer, updateAccessToken, verifyChallenge],
  );

  const disconnect = useCallback(() => {
    disconnectWallet();
    removeAccessToken();
  }, [disconnectWallet, removeAccessToken]);

  const initGuestToken = useCallback(async () => {
    if (accessToken) {
      return;
    }

    const guestToken = await getGuestToken();
    updateAccessToken(guestToken);
  }, [accessToken, getGuestToken, updateAccessToken]);

  const reinitAccessToken = useCallback(async () => {
    removeAccessToken();
    const guestToken = await getGuestToken();
    updateAccessToken(guestToken);
  }, [getGuestToken, removeAccessToken, updateAccessToken]);

  const handleAccountsChanged = useCallback(
    (data: string[]) => {
      if (data.length === 0) {
        disconnect();
        return;
      }

      const address = utils.getAddress(data[0]);
      if (address === currentAddress) {
        return;
      }

      if (connectedWith === WalletOptionsEnum.WalletConnect) {
        disconnect();
        return;
      }

      removeAccessToken();
      setCurrentAddress(address);
    },
    [connectedWith, currentAddress, disconnect, removeAccessToken, setCurrentAddress],
  );

  const handleDisconnectEvent = useCallback(
    (...args: any) => {
      // Metamask extension bugfix: when the chain is changed and the RPC node is slow a disconnect event is emitted
      if (connectedWith === WalletOptionsEnum.Metamask && args[0]?.code === EthereumErrorCodes.DisconnectedFromChain) {
        return;
      }

      walletHandleDisconnectEvent();
      removeAccessToken();
    },
    [connectedWith, removeAccessToken, walletHandleDisconnectEvent],
  );

  useWalletEvents({
    provider,
    handleAccountsChanged,
    handleErrorEvent,
    handleDisconnect: handleDisconnectEvent,
    handleChainChanged: handleChainChangedEvent,
  });

  const initConnection = useCallback(async () => {
    const isConnected = await initWalletConnection();
    const { isValid: isAccessTokenValid, withWallet: accessTokenWithWallet } = initAccessToken();

    if (!isConnected && isAccessTokenValid && accessTokenWithWallet) {
      removeAccessToken();
    }
  }, [initAccessToken, initWalletConnection, removeAccessToken]);

  useEffect(() => {
    initConnection();
  }, [initConnection]);

  useEffect(() => {
    if (currentAddress && accessTokenWalletAddress && currentAddress !== accessTokenWalletAddress) {
      removeAccessToken();
    }
  }, [accessTokenWalletAddress, currentAddress, removeAccessToken]);

  const value: UserContextType = useMemo(
    () => ({
      signer,
      web3Provider,
      connectedWith,
      connect,
      currentAddress,
      isMetamaskInstalled,
      disconnect,
      isWalletConnected,
      isUserConnected,
      signChallenge,
      initGuestToken,
      isAcceptedChainId,
      walletName,
      reinitAccessToken,
      disconnectWallet,
    }),
    [
      connect,
      connectedWith,
      currentAddress,
      disconnect,
      isMetamaskInstalled,
      isUserConnected,
      isWalletConnected,
      signChallenge,
      signer,
      web3Provider,
      initGuestToken,
      isAcceptedChainId,
      walletName,
      reinitAccessToken,
      disconnectWallet,
    ],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextType => {
  return useContext(UserContext);
};
