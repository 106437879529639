import * as Sentry from '@sentry/nextjs';
import { CaptureContext } from '@sentry/types';

import { isActionDeniedByUser } from './ethereum';

export const logError = (error: unknown, captureContext?: CaptureContext) => {
  Sentry.captureException(error, captureContext);
};

export const logEthereumError = (error: any) => {
  if (isActionDeniedByUser(error)) {
    return;
  }

  logError(error);
};
