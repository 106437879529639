import type { NextPage } from 'next';
import { useCallback } from 'react';
import { useAppContext } from '../../shared/context/App.context';
import { truncateTextMiddle } from '../../shared/utilities/text';
import ShinyButton from '../Buttons/ShinyButton';
import styles from './DrawerWalletAuth.module.css';
import { useUserContext } from '../../shared/context/User.context';

const DrawerWalletAuth: NextPage = () => {
  const { disconnect, currentAddress, isUserConnected } = useUserContext();
  const { setDisplayUserAuth } = useAppContext();

  const handleConnectClick = useCallback(() => setDisplayUserAuth(true), [setDisplayUserAuth]);

  if (isUserConnected) {
    return (
      <div className={styles.root}>
        <p className={styles.address}>{truncateTextMiddle(currentAddress)}</p>
        <ShinyButton medium onClick={disconnect}>
          Disconnect
        </ShinyButton>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <ShinyButton medium onClick={handleConnectClick}>
        Connect Wallet
      </ShinyButton>
    </div>
  );
};

export default DrawerWalletAuth;
