import { useCallback, useMemo } from 'react';
import { toast, UpdateOptions } from 'react-toastify';
import { ToastContentProps } from 'react-toastify/dist/types';

import { isActionDeniedByUser, isRpcError } from '../utilities/ethereum';

export default function useToast() {
  const getDefaultErrorMessage = useCallback(() => {
    return 'An error appeared while trying to execute the action. Please try again later you naughty Degen.';
  }, []);

  const getEthereumErrorMessage = useCallback(
    (error: any) => {
      if (isActionDeniedByUser(error)) {
        return 'Action denied. Second thoughts?';
      }

      if (isRpcError(error)) {
        return 'Network RPC error. Please try again later or change the RPC URL!';
      }

      return getDefaultErrorMessage();
    },
    [getDefaultErrorMessage],
  );

  const handleEthereumError = useCallback(
    (error: any) => {
      toast.error(getEthereumErrorMessage(error));
    },
    [getEthereumErrorMessage],
  );

  const handleError = useCallback(() => {
    toast.error(getDefaultErrorMessage());
  }, [getDefaultErrorMessage]);

  const ethereumPromiseErrorConfig = useMemo(
    (): UpdateOptions => ({
      render: ({ data }: ToastContentProps<any>) => getEthereumErrorMessage(data),
    }),
    [getEthereumErrorMessage],
  );

  return {
    ethereumPromiseErrorConfig,
    handleEthereumError,
    handleError,
  };
}
