import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

import styles from './SignChallengeModal.module.css';

import Modal from '../Modal/Modal';
import { useUserContext } from '../../shared/context/User.context';
import { useAccessTokenContext } from '../../shared/context/AccessToken.context';
import { QueryParamsEnum } from '../../shared/consts/queryParamsEnum';
import { truncateTextMiddle } from '../../shared/utilities/text';
import WalletOptionsEnum from '../../shared/consts/walletOptionsEnum';
import ShinyButton from '../Buttons/ShinyButton';
import usePageVisibility from '../../shared/hooks/usePageVisibility';
import useToast from '../../shared/hooks/useToast';
import { isActionDeniedByUser } from '../../shared/utilities/ethereum';

type SignChallengeModalProps = {
  onClose: () => void;
};

export default function SignChallengeModal({ onClose }: SignChallengeModalProps): ReactElement {
  const [inProgress, setInProgress] = useState(false);
  const { signChallenge, initGuestToken, currentAddress, walletName, connectedWith, disconnectWallet } =
    useUserContext();
  const { accessToken, isAccessTokenWithWallet } = useAccessTokenContext();
  const { isPageVisible } = usePageVisibility();
  const { handleError } = useToast();
  const { query: queryParams } = useRouter();
  const referrerToken = queryParams[QueryParamsEnum.ReferrerToken];

  const truncatedAddress = useMemo(() => {
    return truncateTextMiddle(currentAddress);
  }, [currentAddress]);

  const walletNameText = useMemo(() => {
    if (connectedWith === WalletOptionsEnum.WalletConnect) {
      return `${walletName} (WalletConnect)`;
    }

    return walletName;
  }, [connectedWith, walletName]);

  useEffect(() => {
    if (accessToken) {
      return;
    }

    initGuestToken().catch(() => handleError());
  }, [accessToken, handleError, initGuestToken]);

  useEffect(() => {
    /**
     * We need to check the page visibility because on IOS devices the Websocket breaks
     * If this happens after the signature is triggered then the user cannot sign again
     * More about this bug https://github.com/WalletConnect/walletconnect-monorepo/issues/1023
     * isPageVisible should be removed after the bug is fixed
     */
    if (!accessToken || isAccessTokenWithWallet || !isPageVisible || inProgress) {
      return;
    }

    setInProgress(true);
    signChallenge(referrerToken as string)
      .then(() => {
        onClose();
        toast.success('Clap clap - Wallet connected!');
      })
      .catch((error) => {
        if (!isActionDeniedByUser(error)) {
          handleError();
        }
      });
  }, [
    accessToken,
    isAccessTokenWithWallet,
    onClose,
    signChallenge,
    referrerToken,
    isPageVisible,
    inProgress,
    handleError,
  ]);

  return (
    <Modal title="Sign Challenge" onClose={onClose} containerClassName={styles.container}>
      <div className="d-flex flex-column">
        <span>Please open your wallet and sign the security challenge</span>
        <span className={styles.walletInfo}>
          You are connected with <span className="text-bold">{truncatedAddress}</span> by using{' '}
          <span className="text-bold">{walletNameText}</span>
        </span>
        <div>
          <ShinyButton onClick={disconnectWallet} small>
            Connect another wallet
          </ShinyButton>
        </div>
      </div>
    </Modal>
  );
}
