import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  getLocalStorageAccessToken,
  removeLocalStorageAccessToken,
  setLocalStorageAccessToken,
} from '../utilities/localStorage';
import { parseJwt } from '../utilities/jwt';
import { AccessTokenPayload } from '../models/accessTokenPayload';
import { getDateByUnixTimeStamp } from '../utilities/date';
import { InitAccessTokenResponseType } from '../models/initAccessTokenResponse';

type AccessTokenContextType = {
  accessToken?: string;
  accessTokenWalletAddress?: string;
  isAccessTokenWithWallet: boolean;
  updateAccessToken: (newValue: string) => void;
  removeAccessToken: () => void;
  initAccessToken: () => InitAccessTokenResponseType;
};

export const AccessTokenContext = createContext<AccessTokenContextType>({} as AccessTokenContextType);

export function AccessTokenProvider({ children }: { children: ReactNode }): ReactElement {
  const [accessToken, setAccessToken] = useState<string>();

  const accessTokenPayload = useMemo((): AccessTokenPayload | undefined => {
    if (!accessToken) {
      return;
    }

    return parseJwt<AccessTokenPayload>(accessToken);
  }, [accessToken]);
  const isAccessTokenWithWallet = useMemo(
    () => !!accessTokenPayload?.walletAddress,
    [accessTokenPayload?.walletAddress],
  );
  const accessTokenWalletAddress = useMemo(
    () => accessTokenPayload?.walletAddress,
    [accessTokenPayload?.walletAddress],
  );

  const updateAccessToken = useCallback((newValue: string) => {
    setAccessToken(newValue);
    setLocalStorageAccessToken(newValue);
  }, []);

  const removeAccessToken = useCallback(() => {
    setAccessToken(undefined);
    removeLocalStorageAccessToken();
  }, []);

  const initAccessToken = useCallback((): InitAccessTokenResponseType => {
    const defaultResponse = {
      isValid: false,
      withWallet: false,
    };

    const localStorageAccessToken = getLocalStorageAccessToken();
    if (!localStorageAccessToken) {
      return defaultResponse;
    }

    const payload = parseJwt<AccessTokenPayload>(localStorageAccessToken);
    if (!payload || getDateByUnixTimeStamp(payload.exp).getTime() <= new Date().getTime()) {
      removeLocalStorageAccessToken();
      return defaultResponse;
    }

    setAccessToken(localStorageAccessToken);

    return {
      isValid: true,
      withWallet: !!payload.walletAddress,
    };
  }, []);

  useEffect(() => {
    if (!accessTokenPayload) {
      return;
    }

    const delay = getDateByUnixTimeStamp(accessTokenPayload.exp).getTime() - new Date().getTime();
    const timer = setTimeout(() => {
      removeAccessToken();
    }, delay);

    return () => clearTimeout(timer);
  }, [accessTokenPayload, removeAccessToken]);

  const value: AccessTokenContextType = useMemo(
    () => ({
      accessToken,
      isAccessTokenWithWallet,
      updateAccessToken,
      removeAccessToken,
      initAccessToken,
      accessTokenWalletAddress,
    }),
    [
      accessToken,
      isAccessTokenWithWallet,
      removeAccessToken,
      updateAccessToken,
      initAccessToken,
      accessTokenWalletAddress,
    ],
  );

  return <AccessTokenContext.Provider value={value}>{children}</AccessTokenContext.Provider>;
}

export const useAccessTokenContext = (): AccessTokenContextType => {
  return useContext(AccessTokenContext);
};
