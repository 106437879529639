import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { providers } from 'ethers';

import useWalletConnect from './useWalletConnect';
import { getLocalStorageWalletConnectedWith, setLocalStorageWalletConnectedWith } from '../utilities/localStorage';
import useMetamask from './useMetamask';
import WalletOptionsEnum from '../consts/walletOptionsEnum';
import ConnectWalletResponseType from '../models/connectWalletResponse';
import config from '../../config';
import { logEthereumError } from '../utilities/errorLogger';
import { ConnectWalletType } from '../models/connectWalletType';

export type UseWalletType = {
  disconnect: () => void;
  connect: (wallet: ConnectWalletType) => Promise<boolean>;
  web3Provider?: providers.Web3Provider;
  signer?: providers.JsonRpcSigner;
  currentAddress: string;
  connectedWith: WalletOptionsEnum;
  isMetamaskInstalled: boolean;
  isWalletConnected: boolean;
  provider: any;
  setCurrentAddress: React.Dispatch<React.SetStateAction<string>>;
  handleDisconnectEvent: () => void;
  initConnection: () => Promise<boolean>;
  isAcceptedChainId: boolean;
  handleChainChangedEvent: (newChainId: any) => void;
  handleErrorEvent: (error: any) => void;
  walletName?: string;
};

export default function useWallet(): UseWalletType {
  const [connectedWith, setConnectedWith] = useState(WalletOptionsEnum.None);
  const [currentAddress, setCurrentAddress] = useState('');

  const updateConnectedWith = useCallback((value: WalletOptionsEnum) => {
    setConnectedWith(value);
    setLocalStorageWalletConnectedWith(value);
  }, []);

  const defaultHandler = useCallback(() => {
    throw new Error('Wallet not connected');
  }, []);

  const {
    provider: metamaskProvider,
    web3Provider: metamaskWeb3Provider,
    connect: connectMetamask,
    disconnect: disconnectMetamask,
    isWalletConnected: isWalletConnectedWithMetamask,
    isMetamaskInstalled,
    handleDisconnectEvent: handleDisconnectEventMetamask,
    initConnection: initConnectionMetamask,
    chainId: chainIdMetamask,
    handleChainChangedEvent: handleChainChangedEventMetamask,
    handleErrorEvent: handleErrorEventMetamask,
    walletName: metamaskWalletName,
  } = useMetamask();

  const {
    provider: walletConnectProvider,
    web3Provider: walletConnectWeb3Provider,
    connect: connectWalletConnect,
    disconnect: disconnectWalletConnect,
    isWalletConnected: isWalletConnectedWithWalletConnect,
    handleDisconnectEvent: handleDisconnectEventWalletConnect,
    initConnection: initConnectionWalletConnect,
    chainId: chainIdWalletConnect,
    handleErrorEvent: handleErrorEventWalletConnect,
    handleChainChangedEvent: handleChainChangedEventWalletConnect,
    walletName: walletConnectWalletName,
  } = useWalletConnect();

  const {
    provider,
    web3Provider,
    disconnectWallet,
    handleDisconnectEventWallet,
    handleErrorEvent,
    chainId,
    walletName,
    handleChainChangedEvent,
    isWalletConnectedWithSomething,
  } = useMemo(() => {
    switch (connectedWith) {
      case WalletOptionsEnum.WalletConnect: {
        return {
          provider: walletConnectProvider,
          web3Provider: walletConnectWeb3Provider,
          disconnectWallet: disconnectWalletConnect,
          handleDisconnectEventWallet: handleDisconnectEventWalletConnect,
          handleErrorEvent: handleErrorEventWalletConnect,
          chainId: chainIdWalletConnect,
          walletName: walletConnectWalletName,
          handleChainChangedEvent: handleChainChangedEventWalletConnect,
          isWalletConnectedWithSomething: isWalletConnectedWithWalletConnect,
        };
      }
      case WalletOptionsEnum.Metamask: {
        return {
          provider: metamaskProvider,
          web3Provider: metamaskWeb3Provider,
          disconnectWallet: disconnectMetamask,
          handleDisconnectEventWallet: handleDisconnectEventMetamask,
          handleErrorEvent: handleErrorEventMetamask,
          chainId: chainIdMetamask,
          walletName: metamaskWalletName,
          handleChainChangedEvent: handleChainChangedEventMetamask,
          isWalletConnectedWithSomething: isWalletConnectedWithMetamask,
        };
      }
      default: {
        return {
          isWalletConnectedWithSomething: false,
          disconnectWallet: defaultHandler,
          handleDisconnectEventWallet: defaultHandler,
          handleErrorEvent: defaultHandler,
          handleChainChangedEvent: defaultHandler,
        };
      }
    }
  }, [
    chainIdMetamask,
    chainIdWalletConnect,
    connectedWith,
    defaultHandler,
    disconnectMetamask,
    disconnectWalletConnect,
    handleChainChangedEventMetamask,
    handleChainChangedEventWalletConnect,
    handleDisconnectEventMetamask,
    handleDisconnectEventWalletConnect,
    handleErrorEventMetamask,
    handleErrorEventWalletConnect,
    isWalletConnectedWithMetamask,
    isWalletConnectedWithWalletConnect,
    metamaskProvider,
    metamaskWalletName,
    metamaskWeb3Provider,
    walletConnectProvider,
    walletConnectWalletName,
    walletConnectWeb3Provider,
  ]);

  const connect = useCallback(
    async (walletType: ConnectWalletType) => {
      let response: ConnectWalletResponseType;
      switch (walletType) {
        case WalletOptionsEnum.WalletConnect: {
          response = await connectWalletConnect();
          break;
        }
        case WalletOptionsEnum.Metamask: {
          response = await connectMetamask();
          break;
        }
        // no default
      }

      const { connected } = response;
      if (connected) {
        updateConnectedWith(walletType);
      }

      return connected;
    },
    [connectMetamask, connectWalletConnect, updateConnectedWith],
  );

  const disconnect = useCallback(() => {
    disconnectWallet();
    updateConnectedWith(WalletOptionsEnum.None);
  }, [disconnectWallet, updateConnectedWith]);

  const getAccountAddress = useCallback(async () => {
    const defaultValue = '';
    if (!web3Provider) {
      return defaultValue;
    }

    try {
      return await web3Provider.getSigner().getAddress();
    } catch (error) {
      logEthereumError(error);
      return defaultValue;
    }
  }, [web3Provider]);

  const handleDisconnectEvent = useCallback(() => {
    handleDisconnectEventWallet();
    updateConnectedWith(WalletOptionsEnum.None);
  }, [handleDisconnectEventWallet, updateConnectedWith]);

  const signer = useMemo(() => web3Provider?.getSigner(), [web3Provider]);

  const isWalletConnected = useMemo(
    () => isWalletConnectedWithSomething && !!currentAddress,
    [isWalletConnectedWithSomething, currentAddress],
  );

  const isAcceptedChainId = useMemo(() => {
    return chainId === config.acceptedChainId;
  }, [chainId]);

  const initConnection = useCallback(async (): Promise<boolean> => {
    const localStorageConnectedWith = getLocalStorageWalletConnectedWith();
    if (!localStorageConnectedWith) {
      return false;
    }

    let result;
    switch (localStorageConnectedWith) {
      case WalletOptionsEnum.Metamask: {
        result = await initConnectionMetamask();
        break;
      }
      case WalletOptionsEnum.WalletConnect: {
        result = await initConnectionWalletConnect();
        break;
      }
      // no default
    }

    if (!result) {
      setLocalStorageWalletConnectedWith(WalletOptionsEnum.None);
      return false;
    }

    setConnectedWith(localStorageConnectedWith);

    return true;
  }, [initConnectionMetamask, initConnectionWalletConnect]);

  useEffect(() => {
    getAccountAddress().then((address: string) => {
      setCurrentAddress(address);
    });
  }, [connectedWith, getAccountAddress]);

  return {
    web3Provider,
    signer,
    currentAddress,
    connect,
    disconnect,
    connectedWith,
    isMetamaskInstalled,
    isWalletConnected,
    provider,
    setCurrentAddress,
    handleDisconnectEvent,
    initConnection,
    isAcceptedChainId,
    handleChainChangedEvent,
    handleErrorEvent,
    walletName,
  };
}
