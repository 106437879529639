import { ReactElement, useCallback } from 'react';

import { useAppContext } from '../../shared/context/App.context';
import WalletOptionsModal from '../WalletOptionsModal/WalletOptionsModal';
import { useUserContext } from '../../shared/context/User.context';
import SignChallengeModal from '../SignChallengeModal/SignChallengeModal';

export default function UserAuth(): ReactElement {
  const { displayUserAuth, setDisplayUserAuth } = useAppContext();
  const { connect, isWalletConnected } = useUserContext();

  const onClose = useCallback(() => setDisplayUserAuth(false), [setDisplayUserAuth]);

  if (!displayUserAuth) {
    return <></>;
  }

  if (isWalletConnected) {
    return <SignChallengeModal onClose={onClose} />;
  }

  return <WalletOptionsModal onSubmit={connect} onClose={onClose} />;
}
