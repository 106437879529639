import EthereumErrorCodes from '../consts/ethereumErrors';
import { Web3WalletPermission } from '../models/web3WalletPermission';

export const isActionDeniedByUser = (error: any) => {
  return (
    error.code === EthereumErrorCodes.ActionDenied ||
    error.message === 'User rejected the transaction' ||
    error.message === 'Actionscancelled by user' ||
    error.message === 'MetaMask Personal Message Signature: User denied message signature.' ||
    error.message === 'User rejected methods.'
  );
};

export const isRpcError = (error: any) => {
  return (
    error.code === EthereumErrorCodes.InternalError ||
    (error.code === EthereumErrorCodes.CallException && error.error?.code === EthereumErrorCodes.InternalError)
  );
};

export const hasMultipleWalletPermissions = (permissions: Web3WalletPermission[]) => {
  return permissions[0].caveats[0].value.length > 1;
};
