import type { NextPage } from 'next';
import cx from 'classnames';
import { ReactNode } from 'react';
import styles from './IconButton.module.css';

type IconButtonProps = {
  onClick?: () => void;
  children: ReactNode;
  className?: string;
};

const IconButton: NextPage<IconButtonProps> = ({ onClick, children, className }) => {
  return (
    <div role="button" className={cx(styles.root, className)} onClick={onClick} aria-hidden="true">
      {children}
    </div>
  );
};

export default IconButton;
