import type { NextPage } from 'next';
import { useCallback } from 'react';
import cx from 'classnames';
import styles from './Drawer.module.css';
import CloseIcon from '../../assets/icons/close.svg';
import IconButton from '../Buttons/IconButton';
import { useAppContext } from '../../shared/context/App.context';
import Logo from '../Logo/Logo';
import NavLinks from '../NavLinks/NavLinks';
import DrawerWalletAuth from '../WalletAuth/DrawerWalletAuth';

const Drawer: NextPage = () => {
  const { isDrawerOpen, setIsDrawerOpen } = useAppContext();

  const handleClose = useCallback(() => setIsDrawerOpen(false), [setIsDrawerOpen]);

  return (
    <div className={cx(styles.root, isDrawerOpen && styles.openDrawer)}>
      <IconButton className={styles.closeDrawerButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <div className={styles.container}>
        <Logo medium />
        <div className={styles.menuElementsWrap}>
          <NavLinks />
          <div className={styles.walletArea}>
            <DrawerWalletAuth />
          </div>
        </div>
        <div className={styles.copyrights}>&copy; {new Date().getFullYear()} AlanMusk</div>
      </div>
    </div>
  );
};

export default Drawer;
