import { useRouter } from 'next/router';

import config from '../../config';
import { useGameContext } from '../../shared/context/Game.context';

export default function GameQuittingFix() {
  const { route } = useRouter();
  const { unityInstanceIdentifier } = useGameContext();

  if (route === config.routes.game) {
    return <></>;
  }

  return <div id={`unity-canvas-${unityInstanceIdentifier}`} />;
}
