import type { AppProps } from 'next/app';
import Head from 'next/head';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../styles/globals.css';
import config from '../config';
import { GameProvider } from '../shared/context/Game.context';
import { AppProvider } from '../shared/context/App.context';
import { UserProvider } from '../shared/context/User.context';
import { AccessTokenProvider } from '../shared/context/AccessToken.context';
import Drawer from '../components/Drawer/Drawer';
import UserAuth from '../components/UserAuth/UserAuth';
import GameQuittingFix from '../components/GameQuittingFix/GameQuittingFix';
import CookiesConsentBanner from '../components/CookiesConsentBanner/CookiesConsentBanner';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name="twitter:title" content={config.appName} />
        <meta name="twitter:description" content={config.twitterDescription} />
        <meta property="twitter:image" content={`${config.officialBaseUrl}/meta-twitter.jpg`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
      <AccessTokenProvider>
        <UserProvider>
          <AppProvider>
            <GameProvider>
              <UserAuth />
              <GameQuittingFix />
              <Drawer />
              <CookiesConsentBanner />
              <Component {...pageProps} />
              <ToastContainer theme="colored" />
            </GameProvider>
          </AppProvider>
        </UserProvider>
      </AccessTokenProvider>
    </>
  );
}

export default MyApp;
