export const wrapperStyle = {
  background: '#fff',
  color: '#6F6A6F',
  borderTop: '4px solid #6c6a6f',
  padding: '0px 15px',
  alignItems: 'center',
  justifyContent: 'flex-end',
};

export const buttonStyle = {
  color: '#000',
  background: 'linear-gradient(300deg, #fff096 34%, #fffadc 34%, #fffadc 66%, #fff096 66%)',
  border: '3px solid #c2b671',
  borderRadius: '12px',
  padding: '8px 16px',
};

export const contentStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '30px',
  margin: '8px 15px',
};
