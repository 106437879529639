import { useEffect } from 'react';
import type { NextPage } from 'next';
import CookieConsent from 'react-cookie-consent';
import TagManager from 'react-gtm-module';
import config from '../../config';
import { getCookie } from '../../shared/utilities/cookies';
import CookieImage from '../../assets/cookie.svg';
import styles from './CookiesConsentBanner.module.css';
import { wrapperStyle, buttonStyle, contentStyle } from './CookiesConsentBanner.styles';

const initializeTagManager = () => {
  TagManager.initialize({ gtmId: config.analytics });
};

const CookiesConsentBanner: NextPage = () => {
  useEffect(() => {
    if (getCookie('cookieConsent') === 'true') {
      initializeTagManager();
    }
  }, []);

  return (
    <CookieConsent
      location="bottom"
      buttonText="ACCEPT"
      cookieName="cookieConsent"
      style={wrapperStyle}
      buttonStyle={buttonStyle}
      contentStyle={contentStyle}
      onAccept={initializeTagManager}
    >
      <div className={styles.cookieImageWrapper}>
        <CookieImage width="70" height="70" />
      </div>
      <div>
        Select &quot;Accept&quot; to agree to Alan Musk&apos;s{' '}
        <a href={config.routes.terms} className={styles.cookieTermsLink}>
          terms
        </a>{' '}
        for use of cookies
        <span className={styles.cookieExtraText}>
          {' '}
          and similar technologies to enhance your browsing experience, security, analytics and customization
        </span>
        .
      </div>
    </CookieConsent>
  );
};

export default CookiesConsentBanner;
