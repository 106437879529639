import { ReactNode } from 'react';
import cs from 'classnames';

import styles from './Modal.module.css';
import CloseIcon from '../../assets/icons/close.svg';
import IconButton from '../Buttons/IconButton';

type ModalProps = {
  children: ReactNode;
  title?: ReactNode;
  onClose?: () => void;
  containerClassName?: string;
};

const Modal = ({ children, title, onClose, containerClassName }: ModalProps) => {
  return (
    <div className={styles.root}>
      <div className={cs(styles.container, containerClassName)}>
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};
export default Modal;
