import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

type GameContextType = {
  unityInstanceIdentifier: number;
  incrementUnityInstanceIdentifier: () => void;
};

export const GameContext = createContext<GameContextType>({} as GameContextType);

export function GameProvider({ children }: { children: ReactNode }): ReactElement {
  const [unityInstanceIdentifier, setUnityInstanceIdentifier] = useState(0);

  const incrementUnityInstanceIdentifier = useCallback(() => {
    setUnityInstanceIdentifier((currentValue: number) => currentValue + 1);
  }, []);

  const value: GameContextType = useMemo(
    () => ({
      unityInstanceIdentifier,
      incrementUnityInstanceIdentifier,
    }),
    [incrementUnityInstanceIdentifier, unityInstanceIdentifier],
  );

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
}

export const useGameContext = (): GameContextType => {
  return useContext(GameContext);
};
