import { useCallback } from 'react';

import useFetch from './useFetch';
import config from '../../config';

export type UseAuthType = {
  getChallenge: (walletAddress: string, referrerToken?: string) => Promise<string>;
  verifyChallenge: (challenge: string, signature: string) => Promise<string>;
  getGuestToken: () => Promise<string>;
};

export default function useAuth(): UseAuthType {
  const { get: getLoginGuest } = useFetch({
    path: config.apiRoutes.loginGuest,
  });
  const { post: postLoginWithWallet } = useFetch({
    path: config.apiRoutes.loginWallet,
  });
  const { post: postVerifyChallenge } = useFetch({
    path: config.apiRoutes.verifyChallenge,
  });

  const getChallenge = useCallback(
    async (walletAddress: string, referrerToken?: string) => {
      const response = await postLoginWithWallet({ walletAddress, referrerToken });
      return response.data.challenge as string;
    },
    [postLoginWithWallet],
  );

  const verifyChallenge = useCallback(
    async (challenge: string, signature: string) => {
      const response = await postVerifyChallenge({ challenge, signature });
      return response.data.accessToken as string;
    },
    [postVerifyChallenge],
  );

  const getGuestToken = useCallback(async () => {
    const response = await getLoginGuest();
    return response.data.accessToken as string;
  }, [getLoginGuest]);

  return {
    getChallenge,
    verifyChallenge,
    getGuestToken,
  };
}
