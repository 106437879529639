import EthereumProvider from '@walletconnect/ethereum-provider';
import { providers } from 'ethers';
import { useCallback, useMemo, useState } from 'react';

import config from '../../config';
import ConnectWalletResponseType from '../models/connectWalletResponse';
import { logEthereumError } from '../utilities/errorLogger';
import { WalletCommonType } from '../models/walletCommonType';
import { WalletConnectErrors } from '../consts/walletConnectErrors';

export type UseWalletConnectType = WalletCommonType & {
  handleChainChangedEvent: (newChainId: string | number) => void;
};

export default function useWalletConnect(): UseWalletConnectType {
  const [provider, setProvider] = useState<EthereumProvider>();

  const chainId = useMemo(() => provider?.chainId, [provider?.chainId]);

  const web3Provider = useMemo((): providers.Web3Provider | undefined => {
    if (!provider) {
      return undefined;
    }

    return new providers.Web3Provider(provider);
  }, [provider]);

  const walletName = useMemo(() => {
    if (!provider) {
      return undefined;
    }

    return provider.session?.peer.metadata.name;
  }, [provider]);

  const handleErrorEvent = useCallback((error: any) => {
    // ignore the errors for unsupported chain
    if (error?.message.includes(WalletConnectErrors.NoRpcUrlAvailable)) {
      return;
    }

    logEthereumError(error);
  }, []);

  const connect = useCallback(async (): Promise<ConnectWalletResponseType> => {
    const {
      acceptedChainId,
      rpcUrl,
      walletConnect: { projectId },
    } = config;

    let newProvider;
    let addresses = [];

    try {
      newProvider = await EthereumProvider.init({
        projectId,
        showQrModal: true,
        chains: [acceptedChainId],
        rpcMap: {
          [acceptedChainId]: rpcUrl,
        },
      });

      addresses = await newProvider.enable();
    } catch (error: any) {
      if (!error.message?.startsWith(WalletConnectErrors.ModalClosed)) {
        logEthereumError(error);
      }

      return {
        connected: false,
      };
    }

    if (!addresses.length) {
      return {
        connected: false,
      };
    }

    setProvider(newProvider);

    return {
      connected: true,
    };
  }, []);

  const disconnect = useCallback(async () => {
    if (provider) {
      try {
        await provider.disconnect();
      } catch (error) {
        logEthereumError(error);
      }
      setProvider(undefined);
    }
  }, [provider]);

  const handleDisconnectEvent = useCallback(() => {
    setProvider(undefined);
  }, []);

  const isWalletConnected = useMemo(() => !!web3Provider, [web3Provider]);

  const initConnection = useCallback(async () => {
    const result = await connect();

    return result.connected;
  }, [connect]);

  const handleChainChangedEvent = useCallback((newChainId: string | number) => {
    // do nothing
  }, []);

  return {
    provider,
    web3Provider,
    connect,
    disconnect,
    isWalletConnected,
    handleDisconnectEvent,
    initConnection,
    chainId,
    handleErrorEvent,
    handleChainChangedEvent,
    walletName,
  };
}
