export const truncateTextMiddle = (text: string, chars = 4) => {
  const firstPart = text.slice(0, 2 + chars);
  const lastPart = text.slice(-chars);
  return `${firstPart}...${lastPart}`;
};

export const getTextLastChars = (text: string, chars = 4) => {
  return text.slice(-chars);
};

export const removeNonNumberCharacters = (value: string) => {
  return value.replace(/[^\d.]/g, '');
};

export const isValidFloatNumber = (value: string) => {
  return value.match(/^\d*(\.\d+)?$/);
};
