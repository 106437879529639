import { ReactElement } from 'react';
import styles from './NavLinks.module.css';
import NavButton from '../Buttons/NavButton';
import config from '../../config';
import { useAppContext } from '../../shared/context/App.context';

const {
  routes: { earnings, leaderBoard, roadmap, tokenomics, buy },
} = config;

const NavLinks = (): ReactElement => {
  const { displayContest } = useAppContext();

  return (
    <div className={styles.root}>
      <div className={styles.group}>
        <NavButton to={buy}>Buy</NavButton>
        <NavButton to={earnings}>Earnings</NavButton>
        <NavButton to={leaderBoard}>
          LeaderBoard {displayContest && <span className={styles.contestText}>USDC</span>}
        </NavButton>
      </div>
      <div className={styles.group}>
        <NavButton to={roadmap} alternativeColor>
          Roadmap
        </NavButton>
        <NavButton to={tokenomics} alternativeColor>
          Tokenomics
        </NavButton>
      </div>
    </div>
  );
};

export default NavLinks;
