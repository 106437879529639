import { NextPage } from 'next';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { ReactNode, useCallback } from 'react';
import { useAppContext } from '../../shared/context/App.context';
import style from './NavButton.module.css';

type NavButtonProps = {
  to: string;
  children: ReactNode;
  disabled?: boolean;
  alternativeColor?: boolean;
};

const NavButton: NextPage<NavButtonProps> = ({ to, children, disabled, alternativeColor }) => {
  const router = useRouter();
  const { isDrawerOpen, setIsDrawerOpen } = useAppContext();

  const handleClick = useCallback(() => {
    if (disabled) {
      return;
    }
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
    }
    router.push(to);
  }, [isDrawerOpen, to, router, setIsDrawerOpen, disabled]);

  return (
    <div
      onClick={handleClick}
      className={cx(
        style.root,
        disabled && style.disabled,
        router.route === to && style.active,
        alternativeColor && style.alternativeColor,
      )}
      aria-hidden="true"
    >
      {children}
    </div>
  );
};

export default NavButton;
