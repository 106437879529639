export type ConfigType = {
  appName: string;
  appPageTitleFormat: string;
  twitterDescription: string;
  backendUrl: string;
  cdnUrl: string;
  tweetUrl: string;
  officialBaseUrl: string;
  pendingAnalNftsRefreshRate: number;
  routes: {
    play: string;
    earnings: string;
    poolList: string;
    leaderBoard: string;
    faq: string;
    roadmap: string;
    tokenomics: string;
    litepaper: string;
    game: string;
    twitter: string;
    terms: string;
    share: string;
    poolDetails: string;
    buy: string;
    telegram: string;
  };
  urls: {
    metamask: string;
    ethScan: string;
    ethRPC: string;
  };
  apiRoutes: {
    loginGuest: string;
    loginWallet: string;
    verifyChallenge: string;
    getUserScores: string;
    getTopScores: string;
    getUserRank: string;
    claims: string;
  };
  enableClaim: boolean;
  enableMint: boolean;
  enableStaking: boolean;
  muskTokenAddress: string;
  muskTotalSupply: number;
  usdcMuskLpTokenAddress: string;
  muskTokenDistributorAddress: string;
  analMinterAddress: string;
  analNftAddress: string;
  muskYieldFarmAddress: string;
  usdcMuskLpYieldFarmAddress: string;
  stakingAddress: string;
  gasLimitMultiplier: number;
  analytics: string;
  acceptedChainId: number;
  chains: {
    [key: number]: string;
  };
  rpcUrl: string;
  buyMuskUrl: string;
  contest: {
    active: string;
    startDate: string;
    endDate: string;
    prize: string;
  };
  walletConnect: {
    projectId: string;
  };
  sentry: {
    dsn: string;
    environment: string;
    ignoredErrors: string[];
  };
  contactMail: string;
};

const config: ConfigType = {
  appName: 'Alan Musk',
  appPageTitleFormat: 'Alan Musk - :pageTitle',
  twitterDescription:
    "The game they don't want you to play. It's a ray of light in the darkness. Sunshine on a rainy day. A fart in the fully loaded metro. A way of earning while taking a dump. It`s something different.",
  backendUrl: `${process.env.NEXT_PUBLIC_BACKEND_URL || 'https://api.analmusk.xyz'}/api/v1`,
  cdnUrl: process.env.NEXT_PUBLIC_CDN_URL || 'https://cdn.analmusk.xyz',
  officialBaseUrl: process.env.NEXT_PUBLIC_OFFICIAL_BASE_URL || 'https://analmusk.xyz',
  tweetUrl: 'https://twitter.com/intent/tweet',
  enableClaim: process.env.NEXT_PUBLIC_ENABLE_CLAIM === 'true' || false,
  enableMint: process.env.NEXT_PUBLIC_ENABLE_MINT === 'true' || false,
  enableStaking: process.env.NEXT_PUBLIC_ENABLE_STAKING === 'true' || false,
  muskTokenAddress: process.env.NEXT_PUBLIC_MUSK_TOKEN_ADDRESS || '0x10f2cf6ef155460c5b716080eb57928652867f2e',
  muskTotalSupply: Number(process.env.NEXT_PUBLIC_MUSK_TOTAL_SUPPLY) || 1000000000000,
  usdcMuskLpTokenAddress:
    process.env.NEXT_PUBLIC_USDC_MUSK_LP_TOKEN_ADDRESS || '0x6d48B3bBD3904CfbaAC8cc2Ef7cB11F861E7283a',
  muskTokenDistributorAddress:
    process.env.NEXT_PUBLIC_MUSK_TOKEN_DISTRIBUTOR_ADDRESS || '0xd243C7D6870e149eA607Aa043EaFc5DD3a0a52d1',
  analMinterAddress: process.env.NEXT_PUBLIC_ANAL_MINTER_ADDRESS || '0xBb4c1493d131522330421466FC4e4C8243000d4F',
  analNftAddress: process.env.NEXT_PUBLIC_ANAL_NFT_ADDRESS || '0x06bc62463be1d704faa7047395bcde1b612fa33b',
  stakingAddress: process.env.NEXT_PUBLIC_STAKING_ADDRESS || '0x761CB073fAD9F215Cd6eb7Ba3eac9bBFe9f891f1',
  usdcMuskLpYieldFarmAddress:
    process.env.NEXT_PUBLIC_USDC_MUSK_LP_YIELD_FARM_ADDRESS || '0x45c8E76610E565AD0d3A306ca8FaABa4390AcC0a',
  muskYieldFarmAddress: process.env.NEXT_PUBLIC_MUSK_YIELD_FARM_ADDRESS || '0x45c8E76610E565AD0d3A306ca8FaABa4390AcC0a',
  gasLimitMultiplier: 1.2,
  pendingAnalNftsRefreshRate: 60000,
  routes: {
    play: '/',
    earnings: '/earnings',
    poolList: '/staking',
    leaderBoard: '/leaderboard',
    faq: '/faq',
    roadmap: '/roadmap',
    tokenomics: '/tokenomics',
    litepaper: 'https://litepaper.analmusk.com/welcome-to-gitbook/',
    game: '/game',
    twitter: 'https://twitter.com/alan_musk_com',
    terms: '/terms',
    share: '/s/:id',
    poolDetails: '/staking/:id',
    buy: '/buy',
    telegram: 'https://t.me/+DDvxnSvopShiYjc0',
  },
  apiRoutes: {
    loginGuest: '/sessions/login/guest',
    loginWallet: '/sessions/login/wallet',
    verifyChallenge: '/sessions/verify-challenge',
    getUserScores: '/leaderboard/user/score-history',
    getTopScores: '/leaderboard/top',
    getUserRank: '/leaderboard/user/rank',
    claims: '/leaderboard/claims',
  },
  urls: {
    metamask: 'https://metamask.io',
    ethScan: 'https://etherscan.io',
    ethRPC: 'https://mainnet.infura.io/v3/',
  },
  analytics: 'GTM-TBCDDLR',
  acceptedChainId: parseInt(process.env.NEXT_PUBLIC_ACCEPTED_CHAIN_ID || '0', 10) || 11155111,
  chains: {
    1: 'Ethereum Mainnet',
    11155111: 'Sepolia',
  },
  rpcUrl: process.env.NEXT_PUBLIC_RPC_URL || 'https://rpc2.sepolia.org',
  buyMuskUrl: process.env.NEXT_PUBLIC_BUY_MUSK_URL || '',
  contest: {
    active: process.env.NEXT_PUBLIC_CONTEST_ACTIVE || 'false',
    startDate: process.env.NEXT_PUBLIC_CONTEST_START_DATE || '2022-05-01',
    endDate: process.env.NEXT_PUBLIC_CONTEST_END_DATE || '2022-05-28',
    prize: process.env.NEXT_PUBLIC_CONTEST_PRIZE || '2000 USDC',
  },
  walletConnect: {
    projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID as string,
  },
  sentry: {
    dsn:
      process.env.NEXT_PUBLIC_SENTRY_DSN ||
      'https://6e98f224740d4467a3756e57a145d8de@o1283123.ingest.sentry.io/6501806',
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'development',
    ignoredErrors: [
      // Unity errors
      "Cannot read property 'getBoundingClientRect' of null",
      "Cannot read properties of null (reading 'addEventListener')",
      'canvas.getContextSafariWebGL2Fixed is not a function',
      'fullscreen error',
      'null function or function signature mismatch',
      "null is not an object (evaluating 'canvas.getBoundingClientRect')",
      "Cannot read properties of null (reading 'getBoundingClientRect')",
      "Cannot read properties of null (reading 'innerText')",
      'EncodingError: Unable to decode audio data',
      "Cannot read property 'addEventListener' of null",
      "Right-hand side of 'instanceof' is not an object",
      "undefined is not an object (evaluating 'e.buffer')",
      'memory access out of bounds',
    ],
  },
  contactMail: 'contact@alanmusk.com',
};

export default config;
