import { ReactElement, useCallback } from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import style from './Logo.module.css';
import config from '../../config';

type LogoProps = {
  small?: boolean;
  medium?: boolean;
  className?: string;
};

const Logo = ({ small, medium, className }: LogoProps): ReactElement<LogoProps> => {
  const router = useRouter();

  const handleClick = useCallback(() => {
    router.push(config.routes.play);
  }, [router]);

  return (
    <div
      onClick={handleClick}
      className={cx(style.logo, small && style.small, medium && style.medium, className)}
      aria-hidden="true"
    />
  );
};
export default Logo;
