import { ReactElement, useCallback, useEffect } from 'react';

import style from './WalletOptionsModal.module.css';

import Modal from '../Modal/Modal';
import ShinyButton from '../Buttons/ShinyButton';
import WalletOptionsEnum from '../../shared/consts/walletOptionsEnum';
import Metamask from '../../assets/metamask.svg';
import WalletConnect from '../../assets/walletconnect.svg';
import { useUserContext } from '../../shared/context/User.context';
import config from '../../config';
import { ConnectWalletType } from '../../shared/models/connectWalletType';

type WalletOptionsModalProps = {
  onSubmit: (walletOption: ConnectWalletType) => Promise<boolean>;
  onClose: () => void;
};

export default function WalletOptionsModal({ onSubmit, onClose }: WalletOptionsModalProps): ReactElement {
  const { isMetamaskInstalled } = useUserContext();

  const connectWithWalletConnect = useCallback(() => onSubmit(WalletOptionsEnum.WalletConnect), [onSubmit]);
  const connectWithMetamask = useCallback(() => onSubmit(WalletOptionsEnum.Metamask), [onSubmit]);

  useEffect(() => {
    if (!isMetamaskInstalled) {
      connectWithWalletConnect().then((connected: boolean) => {
        if (!connected) {
          onClose();
        }
      });
    }
  }, [connectWithWalletConnect, isMetamaskInstalled, onClose]);

  if (!isMetamaskInstalled) {
    return <></>;
  }

  return (
    <Modal title="Connect" onClose={onClose}>
      <span>Please select {config.chains[config.acceptedChainId]} first.</span>
      <div className={style.root}>
        <ShinyButton onClick={connectWithWalletConnect} className={style.button}>
          <div className={style.iconWrapper}>
            <WalletConnect />
          </div>
          <span>WalletConnect</span>
        </ShinyButton>
        <ShinyButton onClick={connectWithMetamask} className={style.button}>
          <div className={style.iconWrapper}>
            <Metamask />
          </div>
          <span>Metamask</span>
        </ShinyButton>
      </div>
    </Modal>
  );
}
